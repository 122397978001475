import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice } from '@reduxjs/toolkit';
import { axiosBaseQuery } from '../../utilities/rtk-query';
import {
  CapacitySchema,
  CompanyResponse,
  LaneCapacityRequestBody,
  LaneSchema,
  NotificationPreferenceSchema,
} from './myLanesTypes';
import { getDeadheadQuery } from './myLanesUtils';
import {
  DeadheadSearch,
  DeadheadSearchQuery,
} from 'features/findLoads/findLoadsTypes';
import queryString from 'query-string';
import { getLoads } from '../findLoads/findLoadsHttp';
import { AppDispatch } from '../../configureStore';
import { setShowFirstLaneNotificationsPopup } from './formDrawerState';

const updateLoadsCount = async (
  lanes: LaneSchema[],
  dispatch: AppDispatch
): Promise<void> => {
  if (lanes.length === 0) return;

  const result = await Promise.all(
    lanes.map((lane: LaneSchema) => getLoads(getDeadheadQuery(lane)))
  );

  const loadsCount = result
    .filter((loads) => loads.length > 0)
    .reduce((count, loads) => count + loads.length, 0);

  dispatch(loadsCountSlice.actions.loadsAblyCount(loadsCount));
  dispatch(loadsCountSlice.actions.loadCounter(loadsCount));
};

export const myLanesApi = createApi({
  reducerPath: 'myLanesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_LANE_URL!,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getLanes: builder.query<LaneSchema[], string | undefined>({
      query: (userId) => {
        if (!userId) {
          throw new Error('userId is required');
        }
        return {
          url: `/lanes?userId=${encodeURIComponent(userId)}`,
          method: 'GET',
        };
      },
      transformResponse: (response: LaneSchema[]) => {
        // Sort lanes by most recently created at the top
        response.sort((a, b) => {
          if (a.createdAt && b.createdAt)
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          else return 0;
        });
        return response;
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data: lanes } = await queryFulfilled;
          await updateLoadsCount(lanes, dispatch);
        } catch (error) {
          console.error('Error fetching additional data:', error);
        }
      },
    }),
    getNotificationPreference: builder.query<
      NotificationPreferenceSchema,
      string | undefined
    >({
      query: (userId) => {
        if (!userId) {
          throw new Error('userId is required');
        }
        return {
          url: `/user/${encodeURIComponent(
            userId
          )}/lanesNotificationPreference`,
          method: 'GET',
        };
      },
    }),
    updateNotificationPreference: builder.mutation<
      NotificationPreferenceSchema,
      {
        userId: string | undefined;
        notificationPreference: NotificationPreferenceSchema;
      }
    >({
      query: ({ userId, notificationPreference }) => {
        if (!userId) {
          throw new Error('userId is required');
        }
        return {
          url: `/user/${userId}/lanesNotificationPreference`,
          method: 'PUT',
          data: notificationPreference,
        };
      },

      async onQueryStarted(
        { userId, notificationPreference },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          myLanesApi.util.updateQueryData(
            'getNotificationPreference',
            userId,
            (draft) => {
              Object.assign(draft, notificationPreference);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    addLane: builder.mutation<
      LaneSchema,
      {
        body: LaneCapacityRequestBody;
        userId: string | undefined;
      }
    >({
      query: ({ body, userId }) => {
        if (!userId) {
          throw new Error('userId is required');
        }
        return {
          url: `/lane`,
          method: 'POST',
          data: body,
        };
      },
      async onQueryStarted({ userId }, { dispatch, queryFulfilled, getState }) {
        const { data: updatedPost } = await queryFulfilled;
        const patchResult = dispatch(
          myLanesApi.util.updateQueryData('getLanes', userId, (draft) => {
            draft.unshift(updatedPost);
          })
        );

        const state = getState();

        const lanes = myLanesApi.endpoints.getLanes.select(userId)(state)?.data;

        const existingNotifications =
          myLanesApi.endpoints.getNotificationPreference.select(userId)(
            state
          )?.data;
        if (!existingNotifications) {
          dispatch(
            myLanesApi.endpoints.getNotificationPreference.initiate(userId)
          ).refetch();
        }

        const isFirstUserLane =
          lanes?.filter((lane) => lane.type === 'USER').length === 1;
        if (isFirstUserLane) {
          dispatch(setShowFirstLaneNotificationsPopup(true));
        }

        try {
          await updateLoadsCount(lanes!, dispatch);
        } catch (error) {
          console.log('error Add Lane :>> ', error);
          patchResult.undo();
          throw new Error('Failed to add lane');
        }
      },
    }),
    updateLane: builder.mutation<
      LaneSchema,
      {
        userId: string | undefined;
        laneId: string | undefined;
        body: LaneCapacityRequestBody;
      }
    >({
      query: ({ userId, body, laneId }) => {
        if (!userId) {
          throw new Error('userId is required');
        }
        if (!laneId) {
          throw new Error('laneId is required');
        }
        return {
          url: `/lanes/${laneId}`,
          method: 'PATCH',
          data: { ...body },
        };
      },
      async onQueryStarted(
        { userId, body },
        { dispatch, queryFulfilled, getState }
      ) {
        const { data: updatedPost } = await queryFulfilled;

        const patchResult = dispatch(
          myLanesApi.util.updateQueryData('getLanes', userId, (draft) =>
            draft.map((lane) => {
              if (lane._id === body.lane._id) {
                return {
                  ...updatedPost,
                };
              }
              return lane;
            })
          )
        );

        const state = getState();

        const lanes = myLanesApi.endpoints.getLanes.select(userId)(state)?.data;

        try {
          await updateLoadsCount(lanes!, dispatch);
        } catch (error) {
          patchResult.undo();
          throw new Error('Failed to add lane');
        }
      },
    }),
    addCapacity: builder.mutation<
      CapacitySchema,
      {
        capacity: CapacitySchema;
        userId: string | undefined;
        laneId: string | undefined;
      }
    >({
      query: ({ capacity, userId, laneId }) => {
        if (!userId) {
          throw new Error('userId is required');
        }
        if (!laneId) {
          throw new Error('laneId is required');
        }
        return {
          url: `/lanes/${laneId}/capacity`,
          method: 'POST',
          data: { capacity: capacity },
        };
      },
      async onQueryStarted({ userId, laneId }, { dispatch, queryFulfilled }) {
        const { data: updatedPost } = await queryFulfilled;

        const patchResult = dispatch(
          myLanesApi.util.updateQueryData('getLanes', userId, (draft) =>
            draft.map((lane) => {
              if (lane._id === laneId) {
                return { ...lane, capacity: updatedPost };
              }
              return lane;
            })
          )
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
          throw new Error('Failed to add lane');
        }
      },
    }),
    deleteLane: builder.mutation<
      string,
      {
        laneId: string;
        userId: string | undefined;
      }
    >({
      query: ({ laneId, userId }) => {
        if (!userId) {
          throw new Error('userId is required');
        }
        if (!laneId) {
          throw new Error('laneId is required');
        }

        return {
          url: `/lanes/${laneId}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted({ laneId, userId }, { dispatch, getState }) {
        const patchResult = dispatch(
          myLanesApi.util.updateQueryData('getLanes', userId, (draft) =>
            draft.filter((obj) => obj._id !== laneId)
          )
        );

        const state = getState();

        const lanes = myLanesApi.endpoints.getLanes.select(userId)(state)?.data;

        try {
          await updateLoadsCount(lanes!, dispatch);
        } catch (error) {
          patchResult.undo();
          throw new Error('Failed to delete lane');
        }
      },
    }),
    deleteCapacity: builder.mutation<
      string,
      {
        capacityId: string | undefined;
        laneId: string | undefined;
        userId: string | undefined;
      }
    >({
      query: ({ capacityId, userId }) => {
        if (!userId) {
          throw new Error('userId is required');
        }
        if (!capacityId) {
          throw new Error('capacityId is required');
        }

        return {
          url: `/capacity/${capacityId}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted({ userId, laneId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          myLanesApi.util.updateQueryData('getLanes', userId, (draft) =>
            draft.map((lane) => {
              if (lane._id === laneId) {
                const { capacity, ...laneWithoutCapacity } = lane;
                return laneWithoutCapacity;
              }
              return lane;
            })
          )
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
          throw new Error('Failed to delete capacity.');
        }
      },
    }),
  }),
});

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_COMPANY_URL!,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getCompany: builder.query<string, string | undefined>({
      query: (email) => {
        if (!email) {
          throw new Error('email is required');
        }
        return {
          url: `/company/${encodeURIComponent(email)}`,
          method: 'GET',
        };
      },
      transformResponse: (response: CompanyResponse[]) => {
        return response[0].company_email;
      },
    }),
  }),
});

export const ryanTransApi = createApi({
  reducerPath: 'ryanTransApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL!,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    GetLoadsForLane: builder.query<DeadheadSearch[], LaneSchema>({
      query: (lane) => {
        const query: DeadheadSearchQuery = getDeadheadQuery(lane);

        return {
          url: `orders/deadhead?${queryString.stringify(query)}`,
          method: 'GET',
        };
      },
      transformResponse: (response: { data: DeadheadSearch[] }) => {
        return response.data;
      },
    }),
  }),
});

const loadsCountState: {
  loadsCount: number;
} = {
  loadsCount: 0,
};

export const loadsCountSlice = createSlice({
  name: 'loadsCount',
  initialState: loadsCountState,
  reducers: {
    loadCounter: (state, count) => {
      state.loadsCount = count.payload;
    },
    loadsAblyCount: (state, count) => {
      state.loadsCount = count.payload;
    },
  },
});

const initialJoyrideState: {
  showJoyride: boolean;
  showAvailableLoads: boolean;
  joyrideSeen: boolean;
} = {
  showJoyride: false,
  showAvailableLoads: false,
  joyrideSeen: false,
};

export const joyrideSlice = createSlice({
  name: 'joyride',
  initialState: initialJoyrideState,
  reducers: {
    setShowJoyride: (state, showJoyride) => {
      state.showJoyride = showJoyride.payload;
    },
    setShowAvailableLoads: (state, showAvailableLoads) => {
      state.showAvailableLoads = showAvailableLoads.payload;
    },
    setJoyrideSeen: (state, joyrideSeen) => {
      state.joyrideSeen = joyrideSeen.payload;
    },
  },
});

export const {
  useGetLanesQuery,
  useGetNotificationPreferenceQuery,
  useUpdateNotificationPreferenceMutation,
  useAddLaneMutation,
  useDeleteLaneMutation,
  useUpdateLaneMutation,
  useAddCapacityMutation,
  useDeleteCapacityMutation,
} = myLanesApi;

export const { useGetCompanyQuery } = companyApi;

export const { loadCounter, loadsAblyCount } = loadsCountSlice.actions;

export const { setShowJoyride, setShowAvailableLoads, setJoyrideSeen } =
  joyrideSlice.actions;

export const { useGetLoadsForLaneQuery } = ryanTransApi;

export default myLanesApi;
