import { City } from 'features/myLanesLegacy/myLanesTypes';
import {
  getStateOrProvinceAbbreviation,
  getStateOrProvinceName,
} from '../../utilities/locations/abbreviationUtils';
import { DeadheadSearchQuery } from '../findLoads/findLoadsTypes';
import {
  CityLocation,
  EquipmentTypes,
  FrequencyTimeFrame,
  LaneAndCapacityForm,
  LaneCapacityRequestBody,
  LaneSchema,
  LaneSchemaWithLoads,
  LaneType,
  LoadDetailsType,
  LocationType,
  RateType,
} from './myLanesTypes';

export const getLocationCoordinates = (
  location: string
): Promise<[number, number]> => {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: location }, (results, status) => {
      if (status === 'OK') {
        const coordinates =
          results &&
          ([
            results[0].geometry.location.lng(),
            results[0].geometry.location.lat(),
          ] as [number, number]);
        if (coordinates) {
          resolve(coordinates);
        } else {
          reject('Coordinates not found');
        }
      } else {
        reject(
          `Geocode was not successful for the following reason: ${status}`
        );
      }
    });
  });
};

export const buildLaneAndCapacityRequest = async (
  formObj: LaneAndCapacityForm,
  addCapacity?: boolean
): Promise<LaneCapacityRequestBody> => {
  const {
    pickup,
    locationPickup,
    delivery,
    locationDelivery,
    trailers,
    truckQuantity,
    loadsPerTimeFrame,
    timeFrame,
    trailerType,
    rateAmount,
    selectedRateType,
    additionalServices,
    commentValue,
  } = formObj;

  let pickupCoordinates: [number, number] | undefined = undefined;
  let deliveryCoordinates: [number, number] | undefined = undefined;

  // check if pickupType is city/zip
  if (
    pickup.optionSelected?.option === 'City' &&
    locationPickup.optionSelected?.optionName
  ) {
    // if it is, use google maps api to get the coordinates for the city/zip
    pickupCoordinates = await getLocationCoordinates(
      locationPickup.optionSelected.optionName
    );
  }

  // check if deliveryType is city/zip
  if (
    delivery.optionSelected?.option === 'City' &&
    locationDelivery.optionSelected?.optionName
  ) {
    // if it is, use google maps api to get the coordinates for the city/zip
    deliveryCoordinates = await getLocationCoordinates(
      locationDelivery.optionSelected.optionName
    );
  }

  // get selected trailers
  const selectedTrailers: EquipmentTypes[] = (trailers.multiSelect || [])
    .filter((x) => x.checked)
    .map((option) => option.text as EquipmentTypes);

  const AddLaneRequestBody = {
    // this is not a laneSchema its {lane,capacity}
    lane: {
      pickupType: pickup.optionSelected?.option as 'City' | 'State',
      ...(pickup.optionSelected?.option === 'City' && {
        pickupCity: {
          cityName: locationPickup.optionSelected?.optionName,
          coordinates: pickupCoordinates!,
          type: 'Point',
        },
      }),
      ...(pickup.optionSelected?.option === 'State' && {
        pickupState: locationPickup.optionSelected?.optionName,
      }),
      deliveryType: delivery.optionSelected?.option as 'City' | 'State',
      ...(delivery.optionSelected?.option === 'City' && {
        deliveryCity: {
          cityName: locationDelivery.optionSelected?.optionName,
          coordinates: deliveryCoordinates!,
          type: 'Point',
        },
      }),
      ...(delivery.optionSelected?.option === 'State' && {
        deliveryState: locationDelivery.optionSelected?.optionName,
      }),
      trailerTypes: selectedTrailers,
    },
    // if the form has capacity, add the capacity to the request body
    ...(addCapacity && {
      capacity: {
        trucks: parseInt(truckQuantity?.optionSelected?.optionName || '0'),
        loadsPerTimeFrame: parseInt(
          loadsPerTimeFrame?.optionSelected?.optionName || '0'
        ),
        frequencyTimeFrame: timeFrame?.optionSelected?.optionName || '',
        ...(additionalServices?.multiSelect &&
          additionalServices?.multiSelect?.filter((x) => x.checked).length >
            0 && {
            loadDetailServices: additionalServices.multiSelect
              .filter((x) => x.checked)
              .map((x) => x.text as LoadDetailsType),
          }),
        ...(commentValue?.optionSelected?.optionName && {
          loadDetailNotes: commentValue?.optionSelected?.optionName,
        }),
        rate: [
          {
            trailerType: trailerType?.optionSelected
              ?.optionName as EquipmentTypes,
            rateType:
              (selectedRateType?.optionSelected?.optionName as RateType) ||
              'Flat rate',
            ...(rateAmount?.optionSelected?.optionName &&
              rateAmount.optionSelected.optionName.length > 1 && {
                amount:
                  (selectedRateType?.optionSelected?.optionName as RateType) ===
                  'Flat rate'
                    ? parseInt(
                        rateAmount.optionSelected.optionName.replace(
                          /[^0-9]/g,
                          ''
                        )
                      )
                    : Number(
                        rateAmount.optionSelected.optionName.replace(
                          /[^0-9.]/g,
                          ''
                        )
                      ),
              }),
          },
        ],
      },
    }),
  };

  return AddLaneRequestBody as LaneCapacityRequestBody; //as Partial<LaneSchema>;// this is not a laneSchema its {lane,capacity}
};

export const buildLaneFromSearchRequest = async (formObj: {
  pickupType: string;
  pickupLocation: string[] | City;
  deliveryType: string;
  deliveryLocation: string[] | City;
  trailers: EquipmentTypes[];
}): Promise<{ lane: LaneSchema }> => {
  const {
    pickupType,
    pickupLocation,
    deliveryType,
    deliveryLocation,
    trailers,
  } = formObj;

  let pickupCoordinates: [number, number] | undefined = undefined;
  let deliveryCoordinates: [number, number] | undefined = undefined;

  // check if pickupType is city/zip
  if (
    pickupType === 'City' &&
    !Array.isArray(pickupLocation) &&
    pickupLocation.long &&
    pickupLocation.lat
  ) {
    // if it is get the coordinates. [long, lat]
    pickupCoordinates = [pickupLocation.long, pickupLocation.lat];
  }

  // check if deliveryType is city/zip
  if (
    deliveryType === 'City' &&
    !Array.isArray(deliveryLocation) &&
    deliveryLocation.long &&
    deliveryLocation.lat
  ) {
    // if it is get the coordinates. [long, lat]
    deliveryCoordinates = [deliveryLocation.long, deliveryLocation.lat];
  }

  const AddLaneRequestBody: { lane: LaneSchema } = {
    lane: {
      pickupType: pickupType === 'States' ? 'State' : 'City',
      ...(pickupType === 'City' &&
        !Array.isArray(pickupLocation) && {
          pickupCity: {
            cityName: pickupLocation.cityName,
            coordinates: pickupCoordinates!,
            type: 'Point',
          },
        }),
      // pickupLocation stores state as abbreviation so convert it
      ...(pickupType === 'States' &&
        Array.isArray(pickupLocation) && {
          pickupState: getStateOrProvinceName(pickupLocation[0]),
        }),
      deliveryType: deliveryType === 'States' ? 'State' : 'City',
      ...(deliveryType === 'City' &&
        !Array.isArray(deliveryLocation) && {
          deliveryCity: {
            cityName: deliveryLocation.cityName,
            coordinates: deliveryCoordinates!,
            type: 'Point',
          },
        }),
      // deliveryLocation stores state as abbreviation so convert it
      ...(deliveryType === 'States' &&
        Array.isArray(deliveryLocation) && {
          deliveryState: getStateOrProvinceName(deliveryLocation[0]),
        }),
      trailerTypes: trailers,
      labelFrom: 'Load Board',
    },
  };
  return AddLaneRequestBody;
};

export const getDeadheadQuery = (lane: LaneSchema): DeadheadSearchQuery => {
  const query: DeadheadSearchQuery = {
    equipmentType: lane.trailerTypes.join(','),
  };
  //pickup params
  if (lane.pickupType === 'City' && lane.pickupCity) {
    query.latitude = Math.abs(lane.pickupCity.coordinates[1]);
    query.longitude = Math.abs(lane.pickupCity.coordinates[0]);
    query.pickupDeadheadMiles = 50;
  } else if (
    (lane.pickupType === 'State' || !lane.pickupType) &&
    lane.pickupState
  ) {
    //the deadhead query is expecting the state to be abbreviated
    query.pickupStates = getStateOrProvinceAbbreviation(lane.pickupState);
  }
  //delivery params
  if (lane.deliveryType === 'City' && lane.deliveryCity) {
    query.deliveryLatitude = Math.abs(lane.deliveryCity.coordinates[1]);
    query.deliveryLongitude = Math.abs(lane.deliveryCity.coordinates[0]);
    query.deliveryDeadheadMiles = 50;
  } else if (
    (lane.deliveryType === 'State' || !lane.deliveryType) &&
    lane.deliveryState
  ) {
    //the deadhead query is expecting the state to be abbreviated
    query.deliveryStates = getStateOrProvinceAbbreviation(lane.deliveryState);
  }

  query.orderMode = 'T,P';

  return query;
};

export const formatText = (text: string | string[]) => {
  if (text === undefined || text === null) return '';
  if (Array.isArray(text)) {
    return text.join(', ');
  }
  return text.replace(/,(?=[^\s])/g, ', ');
};

export const formatLocationText = (
  type: 'Pickup' | 'Delivery',
  lane: LaneSchemaWithLoads | LaneSchema
) => {
  if (lane === undefined || lane === null) return '';
  if (type === 'Pickup') {
    switch (lane.pickupType) {
      case 'City':
        return lane.pickupCity?.cityName;
      case 'State':
      default:
        return lane.pickupState ? formatText(lane.pickupState) : '';
    }
  } else {
    switch (lane.deliveryType) {
      case 'City':
        return lane.deliveryCity?.cityName;
      case 'State':
      default:
        return lane.deliveryState ? formatText(lane.deliveryState) : '';
    }
  }
};

//default is van
export const trailers = [
  { text: 'Van', checked: true },
  { text: 'Straight Box Truck', checked: false },
  { text: 'Reefer', checked: false },
  { text: 'Flatbed', checked: false },
  { text: 'Flatbed Hotshot', checked: false },
  { text: 'Step Deck', checked: false },
  { text: 'Power Only', checked: false },
  { text: 'Specialized', checked: false },
];

export const additionalServices = [
  { text: 'Blanket Wrap', checked: false },
  { text: 'Drop Trailer', checked: false },
  { text: 'Hazmat', checked: false },
  { text: 'Tarps', checked: false },
  { text: 'White Glove', checked: false },
];

export const initialForm: LaneAndCapacityForm = {
  pickup: {
    optionSelected: { option: 'City', optionName: 'City or Zip' },
    required: true,
  },
  delivery: {
    optionSelected: { option: 'City', optionName: 'City or Zip' },
    required: true,
  },
  locationPickup: {
    optionSelected: { optionName: '' },
    required: true,
  },
  locationDelivery: {
    optionSelected: { optionName: '' },
    required: true,
  },
  trailers: {
    required: true,
    multiSelect: trailers,
  },
};

export const initialCapacityForm: Partial<LaneAndCapacityForm> = {
  truckQuantity: {
    optionSelected: { optionName: '' },
    required: true,
  },
  loadsPerTimeFrame: {
    optionSelected: { optionName: '' },
    required: true,
  },
  timeFrame: {
    optionSelected: { optionName: '' },
    required: true,
  },
  trailerType: {
    optionSelected: { optionName: '' },
    required: true,
  },
  rateAmount: {
    optionSelected: { optionName: '' },
    required: false,
  },
  selectedRateType: {
    optionSelected: { optionName: 'Flat rate' },
    required: true,
  },
  additionalServices: {
    required: false,
    multiSelect: additionalServices,
  },
  commentValue: {
    optionSelected: { optionName: '' },
    required: false,
  },
};

export const initialErrorHandler = {
  pickup: false,
  delivery: false,
  locationPickup: false,
  locationDelivery: false,
  trailers: false,
  truckQuantity: false,
  loadsPerTimeFrame: false,
  timeFrame: false,
  trailerType: false,
  rateAmount: false,
  selectedRateType: false,
  additionalServices: false,
  commentValue: false,
  showError: false,
};

export const parseRateAmount = (value: string, rateType: string): string => {
  let val = value.replace(/^0*/g, '');
  if (rateType === 'Flat rate') {
    val = val.replace(/[^0-9]/g, '');

    val = val.slice(0, 5);
    if (val.length === 4) {
      val = val.replace(/^(\d)(\d{3})$/, '$1,$2');
    } else if (val.length === 5) {
      val = val.replace(/^(\d{2})(\d{3})$/, '$1,$2');
    }
  } else if (rateType === 'Per mile') {
    val = value.replace(/[^0-9.]/g, '');

    //only allow one period
    if ((val.match(/\./g) || []).length > 1) val = val.slice(0, 2);

    val = val.slice(0, 4);
    if (val.length > 1 && !val.includes('.')) {
      val = val.slice(0, 1) + '.' + val.slice(1);
    }
  }
  return val;
};

export const capitalizeWords = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const JoyrideDummyLanes = [
  {
    _id: '679d203ca5e3b480c4e15a88',
    mcNumber: '1000001',
    mcleodCarrierId: 'DAWRBAIL',
    email: 'suggestedlane@ryantrans.com',
    userId: '',
    deliveryType: 'City' as LocationType,
    pickupType: 'City' as LocationType,
    pickupCity: {
      type: 'Point',
      cityName: 'Nashville, TN',
      coordinates: [-121.970083, 37.375173],
    } as CityLocation,
    deliveryCity: {
      type: 'Point',
      cityName: 'Louisville, KY',
      coordinates: [-84.543351, 41.611033],
    } as CityLocation,
    trailerTypes: ['Reefer'] as EquipmentTypes[],
    isSuggested: true,
    type: 'CARRIER' as LaneType,
    createdAt: new Date('2025-01-31T19:10:52.009Z'),
    createdBy: 'suggestedlane@ryantrans.com',
    updatedAt: new Date('2025-01-31T19:10:52.009Z'),
    updatedBy: 'suggestedlane@ryantrans.com',
    notificationMeta: {
      loadCount: 0,
      loadCountUpdatedOn: new Date('2025-01-31T20:55:11.088Z'),
      previousLoadCount: 0,
    },
  },
  {
    _id: '679d203ca5e3b480c4e15a86',
    mcNumber: '1000001',
    mcleodCarrierId: 'DAWRBAIL',
    email: 'suggestedlane@ryantrans.com',
    userId: '',
    deliveryType: 'City' as LocationType,
    pickupType: 'City' as LocationType,
    pickupCity: {
      type: 'Point',
      cityName: 'Chicago, IL',
      coordinates: [-121.970083, 37.375173],
    } as CityLocation,
    deliveryCity: {
      type: 'Point',
      cityName: 'St. Louis, MO',
      coordinates: [-84.543351, 41.611033],
    } as CityLocation,
    trailerTypes: ['Van', 'Straight Box Truck', 'Reefer'] as EquipmentTypes[],
    isSuggested: false,
    type: 'USER' as LaneType,
    createdAt: new Date('2025-01-31T19:10:52.009Z'),
    createdBy: 'suggestedlane@ryantrans.com',
    updatedAt: new Date('2025-01-31T19:10:52.009Z'),
    updatedBy: 'suggestedlane@ryantrans.com',
    notificationMeta: {
      loadCount: 0,
      loadCountUpdatedOn: new Date('2025-01-31T20:55:11.088Z'),
      previousLoadCount: 0,
    },
    capacity: {
      trucks: 12,
      loadsPerTimeFrame: 5,
      frequencyTimeFrame: 'Week' as FrequencyTimeFrame,
      loadDetailServices: [],
      loadDetailNotes: '',
      rate: [
        {
          trailerType: 'Van' as EquipmentTypes,
          amount: 500,
          rateType: 'Flat rate' as RateType,
        },
      ],

      userId: 'someUserId',
      laneId: '679d203ca5e3b480c4e15a86',
      createdAt: new Date(),
      createdBy: 'ahhh@ugh.com',
      updatedAt: new Date(),
      updatedBy: 'bleh@grrr.com',
    },
  },
  {
    _id: '679d203ca5e3b480c4e15a85',
    mcNumber: '1000001',
    mcleodCarrierId: 'DAWRBAIL',
    email: 'suggestedlane@ryantrans.com',
    userId: '',
    deliveryType: 'City' as LocationType,
    pickupType: 'City' as LocationType,
    pickupCity: {
      type: 'Point',
      cityName: 'Kansas City, MO',
      coordinates: [-121.970083, 37.375173],
    } as CityLocation,
    deliveryCity: {
      type: 'Point',
      cityName: 'Chicago, IL',
      coordinates: [-84.543351, 41.611033],
    } as CityLocation,
    trailerTypes: [
      'Van',
      'Reefer',
      'Flatbed',
      'Flatbed Hotshot',
    ] as EquipmentTypes[],
    isSuggested: false,
    type: 'USER' as LaneType,
    createdAt: new Date('2025-01-31T19:10:52.009Z'),
    createdBy: 'suggestedlane@ryantrans.com',
    updatedAt: new Date('2025-01-31T19:10:52.009Z'),
    updatedBy: 'suggestedlane@ryantrans.com',
    notificationMeta: {
      loadCount: 0,
      loadCountUpdatedOn: new Date('2025-01-31T20:55:11.088Z'),
      previousLoadCount: 0,
    },
  },
  {
    _id: '679d203ca5e3b480c4e15a84',
    mcNumber: '1000001',
    mcleodCarrierId: 'DAWRBAIL',
    email: 'suggestedlane@ryantrans.com',
    userId: '',
    deliveryType: 'City' as LocationType,
    pickupType: 'City' as LocationType,
    pickupCity: {
      type: 'Point',
      cityName: 'St. Louis, MO',
      coordinates: [-121.970083, 37.375173],
    } as CityLocation,
    deliveryCity: {
      type: 'Point',
      cityName: 'Indianapolis, IN',
      coordinates: [-84.543351, 41.611033],
    } as CityLocation,
    trailerTypes: [
      'Van',
      'Reefer',
      'Flatbed',
      'Flatbed Hotshot',
    ] as EquipmentTypes[],
    isSuggested: false,
    type: 'USER' as LaneType,
    createdAt: new Date('2025-01-31T19:10:52.009Z'),
    createdBy: 'suggestedlane@ryantrans.com',
    updatedAt: new Date('2025-01-31T19:10:52.009Z'),
    updatedBy: 'suggestedlane@ryantrans.com',
    notificationMeta: {
      loadCount: 0,
      loadCountUpdatedOn: new Date('2025-01-31T20:55:11.088Z'),
      previousLoadCount: 0,
    },
  },
];

export const JoyrideDummyLoads = [
  {
    id: '3374461',
    weight: 40000,
    commodity: 'WOOD FLOORING HARDWOODS',
    equipmentType: 'V',
    orderMode: 'T',
    price: 800,
    moveDistance: 249,
    deadheadMiles: 7,
    deliveryDeadheadMiles: 3,
    stops: [
      {
        latitude: 39.0267,
        longitude: 94.6553,
        state: 'IL',
        city: 'CHICAGO',
        scheduledArriveEarly: (() => {
          const today = new Date();
          today.setHours(9, 0, 0, 0);
          return today.toISOString();
        })(),
        scheduledArriveLate: (() => {
          const today = new Date();
          today.setHours(9, 0, 0, 0);
          return today.toISOString();
        })(),
        zip: '66202',
        movementSequence: 1,
        orderId: '3374461',
        stopType: 'PU',
        appointmentRequired: 'N',
        location_id: 'OVEROPK1',
      },
      {
        latitude: 32.802878,
        longitude: 96.835264,
        state: 'MO',
        city: 'ST. LOUIS',
        scheduledArriveEarly: (() => {
          const today = new Date();
          const tomorrow = new Date(today);
          tomorrow.setDate(today.getDate() + 1);
          tomorrow.setHours(0, 30, 0, 0);
          return tomorrow.toISOString();
        })(),
        scheduledArriveLate: (() => {
          const today = new Date();
          const tomorrow = new Date(today);
          tomorrow.setDate(today.getDate() + 1);
          tomorrow.setHours(2, 30, 0, 0);
          return tomorrow.toISOString();
        })(),
        zip: '75207',
        movementSequence: 2,
        orderId: '3374461',
        stopType: 'SO',
        appointmentRequired: 'N',
        location_id: 'BU2840',
      },
    ],
    comment: null,
    phone: '844-908-9857',
    movementId: '3600410',
    isBookItNowDisabled: false,
  },
  {
    id: '3374461',
    weight: 44807,
    commodity: 'WOOD FLOORING HARDWOODS',
    equipmentType: 'V',
    orderMode: 'F',
    price: 880,
    moveDistance: 315,
    deadheadMiles: 14,
    deliveryDeadheadMiles: 22,
    stops: [
      {
        latitude: 39.0267,
        longitude: 94.6553,
        state: 'IN',
        city: 'NILES',
        scheduledArriveEarly: (() => {
          const today = new Date();
          today.setHours(2, 0, 0, 0);
          return today.toISOString();
        })(),
        scheduledArriveLate: (() => {
          const today = new Date();
          today.setHours(9, 0, 0, 0);
          return today.toISOString();
        })(),
        zip: '66202',
        movementSequence: 1,
        orderId: '3374461',
        stopType: 'PU',
        appointmentRequired: 'N',
        location_id: 'OVEROPK1',
      },
      {
        latitude: 32.802878,
        longitude: 96.835264,
        state: 'MO',
        city: 'ST. CHARLES',
        scheduledArriveEarly: (() => {
          const today = new Date();
          const tomorrow = new Date(today);
          tomorrow.setDate(today.getDate() + 1);
          tomorrow.setHours(5, 0, 0, 0);
          return tomorrow.toISOString();
        })(),
        scheduledArriveLate: (() => {
          const today = new Date();
          const tomorrow = new Date(today);
          tomorrow.setDate(today.getDate() + 1);
          tomorrow.setHours(6, 30, 0, 0);
          return tomorrow.toISOString();
        })(),
        zip: '75207',
        movementSequence: 2,
        orderId: '3374461',
        stopType: 'SO',
        appointmentRequired: 'N',
        location_id: 'BU2840',
      },
    ],
    comment: null,
    phone: '844-908-9857',
    movementId: '3600410',
    isBookItNowDisabled: false,
  },
  {
    id: '3374461',
    weight: 41276,
    commodity: 'WOOD FLOORING HARDWOODS',
    equipmentType: 'V',
    orderMode: 'F',
    price: 950,
    moveDistance: 337,
    deadheadMiles: 48,
    deliveryDeadheadMiles: 11,
    stops: [
      {
        latitude: 39.0267,
        longitude: 94.6553,
        state: 'WI',
        city: 'PLEASANT PRAIRIE',
        scheduledArriveEarly: (() => {
          const today = new Date();
          today.setHours(13, 0, 0, 0);
          return today.toISOString();
        })(),
        scheduledArriveLate: (() => {
          const today = new Date();
          today.setHours(13, 0, 0, 0);
          return today.toISOString();
        })(),
        zip: '66202',
        movementSequence: 1,
        orderId: '3374461',
        stopType: 'PU',
        appointmentRequired: 'N',
        location_id: 'OVEROPK1',
      },
      {
        latitude: 32.802878,
        longitude: 96.835264,
        state: 'MO',
        city: 'KINLOCH',
        scheduledArriveEarly: (() => {
          const today = new Date();
          const tomorrow = new Date(today);
          tomorrow.setDate(today.getDate() + 1);
          tomorrow.setHours(1, 0, 0, 0);
          return tomorrow.toISOString();
        })(),
        scheduledArriveLate: (() => {
          const today = new Date();
          const tomorrow = new Date(today);
          tomorrow.setDate(today.getDate() + 1);
          tomorrow.setHours(1, 0, 0, 0);
          return tomorrow.toISOString();
        })(),
        zip: '75207',
        movementSequence: 2,
        orderId: '3374461',
        stopType: 'SO',
        appointmentRequired: 'N',
        location_id: 'BU2840',
      },
    ],
    comment: null,
    phone: '844-908-9857',
    movementId: '3600410',
    isBookItNowDisabled: false,
  },
];
