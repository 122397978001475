export interface DeadheadSearch {
  id: string;
  weight: number;
  equipmentType: string;
  orderMode: string;
  price: number | null;
  moveDistance: number;
  deadheadMiles?: number;
  deliveryDeadheadMiles?: number;
  stops: DeadheadStopSearchResponse[];
  comment: string | null;
  phone: string | null;
  isBookItNowDisabled: boolean;
  isAvailable?: boolean;
  commodity: string | null;
}

export interface DeadheadStopSearchResponse {
  latitude: number;
  longitude: number;
  state: string;
  city: string;
  scheduledArriveEarly: string;
  scheduledArriveLate: string;
  zip: string;
  movementSequence: number;
  orderId: string;
  stopType: string;
  appointmentRequired: string;
}

export type DeadheadSearchQuery = {
  latitude?: number;
  longitude?: number;
  pickupDate?: string;
  pickupDeadheadMiles?: number;
  pickupStates?: string;
  deliveryLatitude?: number;
  deliveryLongitude?: number;
  deliveryLocation?: string;
  deliveryDeadheadMiles?: number;
  deliveryStates?: string;
  equipmentType?: string;
  orderMode?: string;
  limit?: number;
};

export interface DeadheadQueryParams {
  pickupStates?: string;
  pickupCityZip?: string;
  pickupProvince?: string;
  pickupDeadhead?: string;
  deliveryStates?: string;
  deliveryCityZip?: string;
  deliveryProvince?: string;
  deliveryDeadhead?: string;
  pickupDate?: string;
  equipmentType?: string;
  sortBy?: string;
  orderMode?: string;
  id?: string;
}

export interface RecentSearches extends DeadheadQueryParams {
  when: string;
}

export interface StopDetails {
  cityStateText: string;
  arrivalText: string;
  deadheadText: string;
  type: string;
  zip: string;
  appointmentRequired: boolean;
}

export interface FindLoadsTableData {
  id: string;
  stops: StopDetails[];
  trailerType: string;
  weight: string;
  loadType: string;
  distance: string;
  price: string;
  hasMultipleStops: boolean;
  phone: string | null;
  notes: string | null;
  isBookItNowDisabled: boolean;
  isAvailable?: boolean;
  commodity: string | null;
}

export interface MapMarker {
  lat: number;
  lng: number;
  type: string;
}

export type SortBy =
  | 'Lowest Deadhead'
  | 'Highest Price'
  | 'Shortest Trip'
  | 'Longest Trip';

export const trailerTypeMap = new Map(
  Object.entries({
    V: 'Van',
    VR: 'Van or Reefer',
    FD: 'Flatbed or Step Deck',
    F: 'Flatbed',
    R: 'Reefer',
    PO: 'Power',
    SD: 'Step Deck',
    FH: 'Flatbed Hotshot',
    CHAS: 'Chassis',
    FT: 'Flatbed Tarps',
    VZ: 'HAZ - Van',
    VF: 'Van or Flatbed',
    RG: 'RGN',
    VM: 'Van Team',
    RC53: "Rail Container 53'",
    AC: 'Auto Carrier',
    VA: 'Van Airride',
    FN: 'Flatbed Conestoga',
    SR: 'Step Deck or RGN',
    VT: 'Van or Flatbed',
    DD: 'Double Drop',
    RM: 'Reefer',
    CN: 'Conestoga',
    SB: 'Straight Box Truck',
    SV: 'Straight Box Truck',
    RZ: 'HAZ - Reefer',
    FM: 'Flatbed',
    WF: 'Van Walking Floor',
    CV: 'Van',
    RA: 'Reefer',
    DT: 'Dump ',
    TS: 'Tanker',
    NU: 'Pneumatic',
    FZ: 'HAZ - Flatbed',
    VG: 'Van Lift Gate',
    ST: 'Stretch Trailer',
    VTE: 'Van Tanker Endor',
    F53: 'Flatbed 53',
    LA: 'Landoll',
    VH: 'Van Hotshot',
    LTL: 'LTL',
    RC: 'Rail Container',
    FC: 'Flatbed',
    LR: 'RGN',
    RC40: "Rail Container 40'",
    DRAY: 'Drayage',
    LB: 'Lowboy',
    WRK: 'Wrecker',
    SN: 'Stepdeck Conestoga',
    SP: 'Steamship',
    BEAM: 'Beam Trailer',
    C: 'Container',
    FF2: 'Flatbed Double',
    FPB: 'Flatbed Forklift',
    HB: 'Hopper Bottem',
    RAIL: 'Rail Car',
    VL: 'Van Logistics',
    VR53: 'Van or Reefer',
    FA: 'Flatbed Airride',
    FO: 'Flatbed 53',
    FSCS: 'Flat, Step, Van',
    FTE: 'Flatbed Haz',
    RC45: "Rail Container 45'",
    RO: 'Landoll',
    VC: 'Van Curtainn',
    VN: 'Van Intermodal',
    '4TPS': 'Flatbed Tarps',
    '50': 'LTL',
    '6TPS': 'Flatbed Tarps',
    '8TPS': 'Flat',
    AINS: 'Other',
    AWG: 'AWG Insured',
    BT: 'B-Train',
    CARL: 'Cartage Liftgate',
    CART: 'Cartage',
    CHNS: 'Flatbed',
    CHST: 'Flatbed',
    CI: 'Container Refrig',
    CLTR: 'Van Food Grade',
    COBR: 'Other',
    CR: 'Container Refrig',
    CS: '',
    CTPA: 'C-TPAT',
    DAYC: 'Day Cab',
    DBE: 'DBE',
    DFA: 'DFA Contracted Carrier',
    DUN: 'Dunnage',
    ESC: 'Escort Service',
    FAB: 'Flatbed',
    FAST: 'Fast Pass',
    FB: 'Flatbed Boom',
    FBS: 'Flatbed w/Sides',
    FGT: 'Tanker',
    FMS: 'FMS',
    FPT: 'Pole Trailer',
    FR: 'Flatbed, Van, or Reefer',
    FS: 'Flatbed w/Sides',
    FZTE: 'Flatbed Haz',
    GATE: 'Gates Contract ',
    HA: 'Air',
    HC: 'Hopper Convertible',
    HEAV: 'Heavy Haul',
    IR: 'Reefer',
    LDBR: 'Load Bars',
    LO: 'Lowboy',
    LOG: 'Logging',
    MNRD: 'Menards Preferred',
    MV: 'Moving Van',
    MX: 'Flatbed Maxi',
    OT: 'Van Opentop',
    PPE: 'PPE',
    PRI: 'Private Carrier',
    R2: 'Reefer',
    RAMP: 'Step Deck Ramps',
    RC20: "Rail Container 20'",
    RC48: "Rail Container 48'",
    RDE: 'Reefer',
    RFL: 'RTS Fuel Lead',
    RIG: 'Riggers',
    RL: 'Reefer',
    RLTL: 'Reefer',
    RN: 'Reefer',
    RP: 'Reefer',
    RT: 'Reefer',
    RUDR: 'Van Roll Up Doors',
    RV: 'Reefer',
    SCRA: 'SCRA ',
    SPX: 'SPX Contract',
    STPS: 'Van Straps',
    SWDR: 'Swing Doors',
    SWTP: 'Smartway',
    TA: 'Tanker',
    TAA: 'Tanker',
    TD: 'Tanker',
    TEND: 'Tanker',
    TFG: 'Tanker',
    TFK: 'Tanker',
    TN: 'Tanker',
    TNK: 'Tanker',
    TT: 'Van',
    TWIC: 'TVAN TWIC',
    UPS: 'UPS Contract',
    USDR: 'US Citizen Only ',
    V2: 'Van Double',
    V3: 'Van Triple',
    V53: '',
    VB: 'Van Rolling Floor',
    VBW: 'Van Blankets',
    VDD: 'Van Moving',
    VI: 'Van Insulated',
    VN48: 'Van Intermodal 48',
    VN53: 'Van Intermodal 53',
    VP: 'Van Pallets',
    VRD: 'Van Roll Doors',
    VRH: 'Van or Reefer',
    VS: 'Van Conestoga',
    VV: 'Van Vented',
    VW: 'Van Blankets',
    VWF: 'Van Walking Floor',
    VZTE: 'Van Haz',
    WGLV: 'Van White Glove',
    WM: 'WM Contracted Carrier',
    WTMS: 'WM Carrier',
    VDT: 'Van',
    RDT: 'Reefer',
    FDT: 'Flatbed',
  })
);
