import React, { FC, HTMLAttributes } from 'react';
import { SecondaryNavigation } from 'shamrock-clover-ui/dist/clover/components/SecondaryNavigation/SecondaryNavigation';
import CloverThemeProvider from 'shamrock-clover-ui/dist/clover/Theme/ThemeProvider';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '../theme';
import Icon from './Icon';
import './subheader.css';

interface SubHeaderProps {
  v2?: boolean;
  header?: React.JSX.Element;
  tourDisabled?: boolean;
}
export const IconContainer = styled.span`
  display: inline-flex;
  height: 100%;
  align-items: center;
`;

export const ContactLink = styled.a`
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  text-decoration: none;
  vertical-align: super;
  color: ${({ theme }) => theme.colors.textFive};
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`;

export const TakeTourContainer = styled.div<{
  sidebarEnable?: boolean;
}>`
  display: flex;
  padding-right: ${({ sidebarEnable }) => (sidebarEnable ? '20px' : '64')};
  height: 40px;
  align-items: center;
  flex-direction: row;
  &:hover {
    text-decoration: none;
    text-decoration: underline;
    filter: brightness(0.7);
    text-decoration-color: #075c8e;
  }
`;

const V2Style = styled.div<{ sidebarEnable?: boolean }>`
  display: flex;
  position: ${({ sidebarEnable }) => sidebarEnable && 'relative'};
  top: ${({ sidebarEnable }) => sidebarEnable && -5};
`;

export const StyledSecondaryNavigation = styled(SecondaryNavigation)`
  #secondaryNav-element-0 {
    padding-left: 13px;
  }
`;

const MyLanesJoyrideSubHeader: FC<
  RouteComponentProps & SubHeaderProps & HTMLAttributes<HTMLDivElement>
> = ({ style, v2, header, tourDisabled }) => {
  const { t } = useTranslation();
  const loadsCount = 72;

  const toolTipPlacement: 'bottom' | 'left' | 'right' | 'top' | undefined =
    'top';
  const navtabs = [
    {
      title: t('Load_Board'),
    },
    {
      title: t('My Lanes'),
      badge: {
        content: `${loadsCount}`,
        color: '#0091EA',
        tooltipText: t('Loads available'),
        tooltipPlacement: toolTipPlacement,
      },
    },
  ];

  return (
    <CloverThemeProvider>
      <div style={style}>
        <div style={{ paddingLeft: `${v2 ? 24 : 65}` }}>
          <StyledSecondaryNavigation
            id="secondaryNav"
            tabs={navtabs}
            startingIndex={1}
            horizontalPadding={'0'}
            v2={v2}
          />
        </div>
        <V2Style sidebarEnable={v2}>
          {!tourDisabled && (
            <TakeTourContainer sidebarEnable={v2}>
              <ContactLink>{t('take_a_tour')}</ContactLink>
              <IconContainer className="IconContainer">
                <Icon height={24} width={27} name="Tour" alt="take a tour" />
              </IconContainer>
            </TakeTourContainer>
          )}
          {header}
        </V2Style>
      </div>
    </CloverThemeProvider>
  );
};

export default withRouter(MyLanesJoyrideSubHeader);
