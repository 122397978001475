import { BaseQueryFn } from '@reduxjs/toolkit/query';
// import { addInterceptors } from '../services/Service';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getAuthToken } from './authUtils';

interface Auth0Context {
  getAccessTokenSilently?: Auth0ContextInterface['getAccessTokenSilently'];
  isAuthenticated?: boolean;
  logout?: Auth0ContextInterface['logout'];
}

const auth0Context: Auth0Context = {
  getAccessTokenSilently: undefined,
  isAuthenticated: false,
  logout: undefined,
};

export const axiosBaseQuery = (
  { baseUrl }: { baseUrl: string } = { baseUrl: '' }
): BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
  },
  unknown,
  unknown
> => {
  const axiosInstance = axios.create({ baseURL: baseUrl });
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getAuthToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      }
      if (auth0Context.getAccessTokenSilently) {
        const token = await auth0Context.getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      }

      return config;
    },
    (error: AxiosError): Promise<AxiosError> => Promise.reject(error)
  );

  // add interceptor for 401 and 403 redirects
  //   addInterceptors(axiosInstance, true);

  return async ({ url, method, data, params }) => {
    try {
      const result = await axiosInstance({
        url,
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
};
