import React, { FC, HTMLAttributes } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import './subheader.css';
import { useSelector } from 'react-redux';
import { RootState } from 'configureStore';
import SubHeader from './SubHeader';
import MyLanesJoyrideSubHeader from './MyLanesJoyrideSubHeader';

interface SubHeaderProps {
  prefix?: string;
  v2?: boolean;
  header?: React.JSX.Element;
  tourDisabled?: boolean;
}

const SubHeaderParent: FC<
  RouteComponentProps & SubHeaderProps & HTMLAttributes<HTMLDivElement>
> = ({ prefix, style, v2, header, tourDisabled }) => {
  const showJoyride = useSelector(
    (state: RootState) => state.joyride
  ).showJoyride;

  // A separate SubHeader for the joyride is used because the real SubHeader will re-render a lot with new load counts
  // and other data and causes the joyride to have incorrect placement or flash for each re-render
  if (showJoyride) {
    return (
      <MyLanesJoyrideSubHeader
        v2={v2}
        header={header}
        tourDisabled={tourDisabled}
        style={style}
      />
    );
  }

  return (
    <SubHeader
      prefix={prefix}
      v2={v2}
      header={header}
      tourDisabled={tourDisabled}
      style={style}
    />
  );
};

export default withRouter(SubHeaderParent);
