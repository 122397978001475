import { Global, css } from '@emotion/core';
import styled, { CreateStyled } from '@emotion/styled';
import { createTheme } from '@material-ui/core';
import * as React from 'react';

export interface TextColorType {
  textOne: '#333333';
  textTwo: '#666666';
  textThree: '#424242';
  textFour: '#888888';
  textFive: '#0083CA';
  textSix: '#BBBBBB';
  textSeven: '#62C3FF';
  textBlue: '#0091EA';
}

interface Colors extends TextColorType {
  backgroundOne: '#FFFFFF';
  backgroundTwo: '#f9f9f9';
  backgroundThree: '#EEEEEE';
  backgroundFour: '#D8D8D8';
  backgroundFive: '#E5E5E5';
  highlightOne: '#0083CA';
  dropshadow: 'rgba(0, 0, 0, 0.5)';
  error: '#b71c1c';
  green: '#72BF44';
  divider: '#CCCCCC';
  brand: '#0091EA';
}

interface Font {
  weight: {
    regular: 400;
    semiBold: 600;
    bold: 800;
  };
  size: {
    small: '13px';
    medium: '14px';
    mediumLarge: '16px';
    large: '18px';
    xlarge: '20px';
  };
}

type Theme = {
  colors: Colors;
  font: Font;
};

export const Theme: Theme = {
  colors: {
    textOne: '#333333',
    textTwo: '#666666',
    textThree: '#424242',
    textFour: '#888888',
    textFive: '#0083CA',
    textSix: '#BBBBBB',
    textSeven: '#62C3FF',
    textBlue: '#0091EA',
    backgroundOne: '#FFFFFF',
    backgroundTwo: '#f9f9f9',
    backgroundThree: '#EEEEEE',
    backgroundFour: '#D8D8D8',
    backgroundFive: '#E5E5E5',
    highlightOne: '#0083CA',
    dropshadow: 'rgba(0, 0, 0, 0.5)',
    error: '#b71c1c',
    green: '#72BF44',
    divider: '#CCCCCC',
    brand: '#0091EA',
  },
  font: {
    weight: {
      regular: 400,
      semiBold: 600,
      bold: 800,
    },
    size: {
      small: '13px',
      medium: '14px',
      mediumLarge: '16px',
      large: '18px',
      xlarge: '20px',
    },
  },
};

export const MaterialTheme = createTheme({
  overrides: {
    MuiMenuItem: {
      root: {
        display: 'block',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: Theme.colors.highlightOne,
        '&:hover': {
          backgroundColor: Theme.colors.highlightOne,
        },
      },
      current: {
        color: Theme.colors.highlightOne,
      },
    },
    MuiCheckbox: {
      root: {
        '&$checked': {
          color: Theme.colors.highlightOne,
        },
      },
      checked: {},
    },
    MuiSelect: {
      root: {
        fontFamily: 'proxima-nova',
        fontSize: '14px',
      },
      selectMenu: {
        maxHeight: '250px',
      },
    },
    MuiListItemText: {
      primary: {
        fontFamily: 'proxima-nova',
        fontSize: '16px',
      },
    },
  },
} as any);

export const GlobalCss = () => (
  <Global
    styles={css`
      body {
        font: ${Theme.font.size.medium} proxima-nova;
        color: ${Theme.colors.textOne};
        background-color: ${Theme.colors.backgroundTwo};
        margin: 0;
      }

      [role='presentation'] {
        & ul {
          padding-top: 8px;
          padding-bottom: 8px;
        }
        & li {
          display: flex;
          flex-direction: row;
          padding: 6px 16px;
        }
      }
    `}
  ></Global>
);

export default styled as CreateStyled<typeof Theme>;
