import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InputsFormErrors,
  LaneAndCapacityForm,
  LaneSchema,
} from './myLanesTypes';
import {
  initialCapacityForm,
  initialErrorHandler,
  initialForm,
} from './myLanesUtils';

export interface FormDrawerStateProps {
  laneAndCapacityFormValues: LaneAndCapacityForm;
  drawerOpen: boolean;
  formType: 'create' | 'update' | null;
  showSuccessIcon: boolean;
  inputsErrors: InputsFormErrors;
  selectedLane: LaneSchema | null;
  showCapacityForm: boolean;
  isCapacityEdited: boolean;
  snackbar: {
    isOpen: boolean;
    message: string;
    variant: 'info' | 'error';
    duration?: number;
  };
  showFirstLaneNotificationsPopup: boolean;
}

const initialFormState: FormDrawerStateProps = {
  laneAndCapacityFormValues: initialForm,
  drawerOpen: false,
  formType: null,
  showSuccessIcon: false,
  snackbar: { isOpen: false, message: '', variant: 'info' },
  inputsErrors: initialErrorHandler,
  selectedLane: null,
  showCapacityForm: false,
  isCapacityEdited: false,
  showFirstLaneNotificationsPopup: false,
};

export const formDrawerSate = createSlice({
  name: 'formDrawer',
  initialState: initialFormState,
  reducers: {
    openAndCloseDrawer: (state, action: PayloadAction<boolean>) => {
      if (!action.payload) {
        state.showSuccessIcon = false;
        state.formType = null;
        state.snackbar = { isOpen: false, message: '', variant: 'info' };
        state.inputsErrors = initialErrorHandler;
      }
      state.drawerOpen = action.payload;
      state.laneAndCapacityFormValues = initialForm;
    },
    setLaneAndCapacityFormValues: (
      state,
      action: PayloadAction<LaneAndCapacityForm>
    ) => {
      state.laneAndCapacityFormValues = {
        ...state.laneAndCapacityFormValues,
        ...action.payload,
      };
    },
    resetCapacityFormValues: (
      state,
      action: PayloadAction<LaneAndCapacityForm>
    ) => {
      state.laneAndCapacityFormValues = {
        ...action.payload,
      };
    },
    showSuccessAnimation: (state, action: PayloadAction<boolean>) => {
      state.showSuccessIcon = action.payload;
    },
    setSnackbar: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        message: string;
        variant: 'info' | 'error';
        duration?: number;
      }>
    ) => {
      state.snackbar = action.payload;
    },
    inputsHasErrors: (state, action: PayloadAction<InputsFormErrors>) => {
      for (const key in action.payload) {
        state.inputsErrors[key as keyof InputsFormErrors] =
          action.payload[key as keyof InputsFormErrors];
      }
    },
    setSelectedLane: (state, action: PayloadAction<LaneSchema | null>) => {
      state.selectedLane = action.payload;
    },
    setShowCapacityForm: (state, action: PayloadAction<boolean>) => {
      state.showCapacityForm = action.payload;
      if (action.payload) {
        state.laneAndCapacityFormValues = {
          ...state.laneAndCapacityFormValues,
          ...initialCapacityForm,
        };
      }
    },
    setIsCapacityEdited: (state, action: PayloadAction<boolean>) => {
      state.showCapacityForm = action.payload;
    },
    setShowFirstLaneNotificationsPopup: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showFirstLaneNotificationsPopup = action.payload;
    },
  },
});

export const {
  openAndCloseDrawer,
  setLaneAndCapacityFormValues,
  resetCapacityFormValues,
  showSuccessAnimation,
  setSnackbar,
  inputsHasErrors,
  setSelectedLane,
  setShowCapacityForm,
  setIsCapacityEdited,
  setShowFirstLaneNotificationsPopup,
} = formDrawerSate.actions;
